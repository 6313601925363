import { Injectable } from '@angular/core';

const map = {
    pc: 'ProductContainer',
    sc: 'ShipmentContainer'
};

@Injectable()
export class ZplEntityCodesService {
    constructor() {}

    getCode(name: string) {
        for (let code in map) {
            if (map[code] === name) {
                return code;

            }

        }

        throw new Error('Unkown QR Entity ' + name);
    };

    getName(code: string) {

        if (!map[code]) {
            throw Error('Unknown QR Entity code ' + code);

        }

        return map[code];
    };

}
