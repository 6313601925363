import { Injectable } from '@angular/core';
import { isTouchDevice } from '@proman/utils';

export const keycodes = {
    backspace: 8,
    tab: 9,
    enter: 13,
    shift: 16,
    ctrl: 17,
    alt: 18,
    pause_break: 19,
    caps_lock: 20,
    escape: 27,
    space: 32,
    page_up: 33,
    page_down: 34,
    end: 35,
    home: 36,
    left_arrow: 37,
    up_arrow: 38,
    right_arrow: 39,
    down_arrow: 40,
    insert: 45,
    delete: 46,
    f1: 112,
    f2: 113,
    f3: 114,
    f4: 115,
    f5: 116,
    f6: 117,
    f7: 118,
    f8: 119,
    f9: 120,
    f10: 121,
    f11: 122,
    f12: 123,
    num_lock: 144,
    scroll_lock: 145
};


@Injectable({ providedIn: 'root' })
export class BarcodeScannerService {
    isTouch: boolean = isTouchDevice();
    callback: (event: KeyboardEvent) => void;
    constructor() { }

    listen = (handler: any) => {
        const buffer: any = [];
        const threshold: any = this.isTouch ? 120 : 60; // ms
        let timer: any = null;

        function notify() {
            const input = buffer.join('').trim();

            clearTimeout(timer);
            buffer.length = 0;
            handler(input);
        }

        this.callback = (event: KeyboardEvent) => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(notify, threshold);

            if (event.charCode == keycodes.enter) {
                event.stopImmediatePropagation();

                return false;
            }

            buffer.push(String.fromCharCode(event.charCode));
        };

        document.body.addEventListener('keypress', this.callback);

    };

    destroy() {
        document.body.removeEventListener('keypress', this.callback);
    }

}
