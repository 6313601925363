import { Injectable } from '@angular/core';
import { ZplEntityCodesService } from './zpl-entity-codes.service';

@Injectable()
export class QrCodeUrlService {
    hostUrl: string;
    qrCodeUrl: string;

    constructor(private zpl: ZplEntityCodesService) {

        this.qrCodeUrl = 'http://' + window.location.hostname + '/qr/';
    }

    encode(entityName: string, id: number) {
        return this.qrCodeUrl + this.zpl.getCode(entityName) + '/' + id;
    }

    decode(url: any): any {
        let match = /\/([a-z]{2})\/(\d+)$/.exec(url.toLowerCase());
        let reg = /^[0-9]{5,}$/.exec(url.toLowerCase());
        let classCode;
        let id;

        if (match) {
            classCode = match[1];
            id = parseInt(match[2]);

            return {
                Entity: this.zpl.getName(classCode),
                id
            };

        } else if (reg) {
            return {
                Entity: 'Barcode',
                barcode: parseInt(url)
            };
        }
    }

}
